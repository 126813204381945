<template>
	<div class="page-sub-box ewm">
		<!-- Search -->
		<div class="locker_setting_list sub_new_style01 sub_ui_box1">
			<div class="page_search_box line_bottom_1px">
				<DxValidationGroup ref="searchValidationGroup">
					<div class="flex space-x-2 inner">
						<div class="mt-2">등록일자</div>
						<DxDateBox
							:edit-enabled="false"
							:styling-mode="stylingMode"
							width="120"
							height="30"
							v-model="searchType.customTypes.dayStart"
							type="date"
							display-format="yyyy.MM.dd"
							dateSerializationFormat="yyyyMMdd"
							dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
							:max="searchType.customTypes.dayEnd"
							maxLength="10"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
						>
							<DxValidator>
								<DxRequiredRule message="생성일자 기간은 필수입니다." />
							</DxValidator>
						</DxDateBox>
						<div class="mt-1">
							~
						</div>
						<DxDateBox
							:styling-mode="stylingMode"
							width="120"
							height="30"
							v-model="searchType.customTypes.dayEnd"
							type="date"
							display-format="yyyy.MM.dd"
							dateSerializationFormat="yyyyMMdd"
							dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
							maxLength="10"
							:min="searchType.customTypes.dayStart"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
						>
							<DxValidator>
								<DxRequiredRule message="생성일자 기간은 필수입니다." />
							</DxValidator>
						</DxDateBox>
						<div class="mt-2">출제횟수</div>
						<DxTextBox
							placeholder="최소"
							:styling-mode="stylingMode"
							v-model="searchType.customTypes.fromCnt"
							width="50"
							:height="30"
							:max="searchType.customTypes.toCnt"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
						>
							<DxValidator>
								<DxRequiredRule message="출제횟수는 필수입니다." />
							</DxValidator>
						</DxTextBox>
						<div class="mt-1">
							~
						</div>
						<DxTextBox
							placeholder="최대"
							:styling-mode="stylingMode"
							v-model="searchType.customTypes.toCnt"
							width="50"
							:height="30"
							class="mar_ri10"
							:min="searchType.customTypes.fromCnt"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
						>
							<DxValidator>
								<DxRequiredRule message="출제횟수는 필수입니다." />
							</DxValidator>
						</DxTextBox>
						<DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
					</div>
				</DxValidationGroup>
			</div>
		</div>
		<esp-dx-data-grid :data-grid="dataGrid" ref="sheetGrid"></esp-dx-data-grid>
	</div>
</template>

<script>
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { DxValidationGroup } from 'devextreme-vue/validation-group';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxSwitch } from 'devextreme-vue/switch';
import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";



let vm = null;

export default {
	components: {
    EspDxDataGrid,
		DxDateBox,
		DxButton,
		DxTextBox,

		DxValidator,
		DxValidationGroup,
		DxRequiredRule,
	},
	props: {},
	watch: {},
	data() {
		return {
			codes: {
				//코드키 사용
				examSheetType: {
					//시험구분
					dataSource: [],
					displayExpr: 'label',
					valueExpr: 'value',
				},
			},
			stylingMode: 'outlined', //outlined, underlined, filled
			searchType: {
				obj: {},
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {
					dayStart: getPastFromToday(11, 'months'),
					dayEnd: getPastFromToday(0, 'days'),
					fromCnt: '0',
					toCnt: '100',
				},
				paramsData: null,
			},
			dataGrid: {
				keyExpr: 'id',
				refName: 'sheetGrid',
				allowColumnResizing: true, //컬럼 사이즈 허용
				showBorders: false, //border 유무
				showColumnHeaders: true, //컬럼 헤더 유무
				showColumnLines: false, //컬럼 세로선 유무
				showRowLines: true, //컬럼 가로선 유무
				rowAlternationEnabled: false,
				hoverStateEnabled: true,
				dataSource: [],
				// width:'200',     // 주석처리시 100%
				height: 'calc(100vh - 370px)', // 주석처리시 100%
				showActionButtons: {
					// 그리드 버튼 노출 설정값
					customButtons: [
						{
							widget: 'dxButton',
							options: {
								icon: '',
								text: '추가',
								elementAttr: { class: 'btn_XS default filled add1' },
								width: 60,
								height: 30,
								onClick() {
									vm.onAddData();
								},
							},
							location: 'before',
						},
						{
							widget: 'dxButton',
							options: {
								icon: '',
								text: '삭제',
								elementAttr: { class: 'btn_XS white light_filled trash' },
								width: 60,
								height: 30,
								onClick() {
									vm.onDeleteData();
								},
							},
							location: 'before',
						},
					],
				},
				callApi: 'CALL_EWM_API',
				grouping: {
					contextMenuEnabled: false,
					autoExpandAll: false,
					allowCollapsing: false,
					expandMode: 'rowClick', // rowClick or buttonClick
				},
				groupPanel: {
					visible: false,
				},
				columnChooser: {
					enabled: false, // 컬럼 Chooser 버튼 사용유무
				},
				loadPanel: {
					enabled: true, // 로딩바 표시 유무
				},
				scrolling: {
					// 미사용시 주석처리
					mode: 'virtual', //스크롤 모드 : ['infinite', 'standard', 'virtual']
				},
				remoteOperations: {
					filtering: false,
					sorting: false,
					grouping: false,
					paging: false,
				},
				paging: {
					// scrolling 미사용시만 적용됨
					enabled: false,
					pageSize: 10,
					pageIndex: 0, // 시작페이지
				},
				pager: {
					visible: false, //페이저 표시 여부
					showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
					allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
					displayMode: 'compact', //표시 모드 : ['full', 'compact']
					showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
					showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
				},
				filterRow: {
					visible: true,
					operationDescriptions: {
						contains: '포함',
					},
				},
				headerFilter: {
					visible: true,
				},
				editing: {
					allowUpdating: false,
					allowDeleting: false,
					allowAdding: false,
					mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
					startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
					selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
				},
				selecting: {
					mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
					selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
					showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
				},
				columns: [
					{
						caption: '시험타입',
						dataField: 'examSheetType',
						alignment: 'center', // left center right
						visible: true,
						width: 130,
						allowHeaderFiltering: true,
						allowSorting: true,
						calculateSortValue: data => {
							return this.$_enums.ewm.examSheetType.values.find(e => data.examSheetType == e.value).label;
						},
						lookup: {
							dataSource: this.$_enums.ewm.examSheetType.values,
							displayExpr: 'label',
							valueExpr: 'value',
						},
					},
					{
						caption: '시험지명',
						dataField: 'examNm',
						alignment: 'left', // left center right
						visible: true,
						allowHeaderFiltering: false,
						allowSorting: true,
						cellTemplate: (container, options) => {
							let aTag = document.createElement('a');
							aTag.innerText = options.value;
							aTag.addEventListener('click', () => {
								vm.onUpdateData(options.data);
							});
							container.append(aTag);
						},
					},
					{
						caption: '문항수',
						dataField: 'sheetRegCnt',
						alignment: 'center', // left center right
						visible: true,
						width: 100,
						allowHeaderFiltering: false,
						allowSorting: true,
					},
					{
						caption: '총점',
						dataField: 'totalScore',
						alignment: 'center', // left center right
						visible: true,
						width: 100,
						allowHeaderFiltering: false,
						allowSorting: true,
					},
					{
						caption: '사용여부',
						dataField: 'viewFl',
						alignment: 'center', // left center right
						visible: true,
						width: 100,
						allowHeaderFiltering: false,
						allowSorting: true,
						calculateSortValue: data => {
							return this.$_enums.common.stringUsedFlag.values.find(e => data.viewFl == e.value).label;
						},
						cellTemplate: (container, options) => {
							const switchBtn = new DxSwitch({
								propsData: {
									value: options.data.viewFl === this.$_enums.common.stringUsedFlag.YES.value,
									disabled: options.data.scheCnt > 0 ? true : false,
									onValueChanged: e => {
										this.onChangeSwitchValue(e, options);
									},
								},
							});
							switchBtn.$mount();
							container.append(switchBtn.$el);
						},
						lookup: {
							dataSource: this.$_enums.common.stringUsedFlag.values,
							displayExpr: 'label',
							valueExpr: 'value',
						},
					},
					{
						caption: '출제횟수',
						dataField: 'scheCnt',
						alignment: 'center', // left center right
						visible: true,
						width: 100,
						allowHeaderFiltering: false,
						allowSorting: true,
					},
					{
						caption: '미리보기',
						dataField: 'preview',
						alignment: 'center', // left center right
						visible: true,
						width: 100,
						allowHeaderFiltering: false,
						allowFiltering: false,
						allowSorting: false,
						cellTemplate: (container, options) => {
							let button = new DxButton({
								propsData: {
									text: '',
									icon: 'search',
									elementAttr: { class: 'white light_filled txt_S' },
									value: options.data,
									onClick: () => {
										vm.onPreviewData(options.data);
									},
								},
							});
							button.$mount();
							container.append(button.$el);
						},
					},
					{
						caption: '등록자',
						dataField: 'regNm',
						alignment: 'center',
						visible: true,
						width: 150,
						allowHeaderFiltering: false,
						allowSorting: true,
						/* cellTemplate: (container, option) => {
							let tag = document.createElement('div');
							let value = `${option.data.regNm} [${option.data.regId}]`;
							tag.innerHTML = value;
							container.append(tag);
						}, */
						calculateCellValue: rowData => {
							return `${rowData.regNm} [${rowData.regId}]`;
						},
					},
					{
						caption: '등록일',
						dataField: 'regDt',
						alignment: 'center',
						visible: true,
						width: 130,
						//sortOrder: 'desc',
						allowHeaderFiltering: false,
						allowSorting: true,
						dataType: 'date',
						format: 'yyyy-MM-dd',
						/* cellTemplate: (container, option) => {
							let tag = document.createElement('div');
							let value = `${vm.regFormatDt(option.data.regDt)}`;
							tag.innerHTML = value;
							container.append(tag);
						}, */
					},
				],
			},
		};
	},
	computed: {
		searchValidationGroup: function() {
			return this.$refs['searchValidationGroup'].instance;
		},
	},
	methods: {
		/** @description: 추가 페이지 이동 이벤트 */
		onAddData() {
			let params = { updateYn: false };
			this.$store.commit('setDetailParams', params);
			this.$router.push({ path: '/ewm/exam/setting/sheet/config' });
		},
		/** @description: 설문제목 클릭 이동 이벤트 */
		onUpdateData(data) {
			console.log('Data : ', data);
			let params = { data: data, updateYn: true };
			this.$store.commit('setDetailParams', params);
			this.$router.push({ path: '/ewm/exam/setting/sheet/config' });
		},
		/** @description: 시험지 미리보기 클릭 이동 이벤트 */
		onPreviewData(data) {
			if (data.sheetRegCnt === 0) {
				this.$_Msg(`등록된 문항이 없습니다.`);
				return;
			}
			let params = { data: data };
			this.$store.commit('setDetailParams', params);
			this.$router.push({ path: '/ewm/exam/setting/sheet/preview' });
		},
		/** @description: 데이터 삭제 이벤트 */
		async onDeleteData() {
			if (this.$refs.sheetGrid.selectedRowsData.length == 0) {
				this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}

			for (const canDelete of this.$refs.sheetGrid.selectedRowsData) {
				if (canDelete.scheCnt > 0) {
					this.$_Confirm('이미 출제된 시험지가 있습니다.');
					return;
				}
			}

			let deletedIds = this.$refs.sheetGrid.selectedRowsData.map(d => {
				return d.id;
			});

			if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
				return;
			}

			let payload = {
				actionname: 'EWM_EXAM_SHEET_DELETE',
				data: {
					data: {
						idList: deletedIds,
					},
				},
				loading: true,
			};
			let res = await this.CALL_EWM_API(payload);
			if (res.status === 200) {
				if (res.data.header.resCode === 'success') {
					this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
					this.$refs.sheetGrid.getGridInstance.refresh();
				} else {
					this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description: 사용여부 전환 이벤트 */
		async onChangeSwitchValue(e, options) {
			/* if (!(await this.$_Confirm('선택 데이터의 사용여부를 변경 하시겠습니까?'))) {
				return;
			} */

			let payload = {
				actionname: 'EWM_EXAM_SHEET_VIEW_FL_UPDATE',
				data: {
					sheetId: options.data.id,
					viewFl: e.value ? 'Y' : 'N',
				},
				loading: true,
			};

			let res = await this.CALL_EWM_API(payload);
			if (res.status === 200) {
				if (res.data.header.resCode === 'success') {
					this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
					this.$refs.sheetGrid.getGridInstance.refresh();
				} else {
					this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}

			//this.$refs.sheetGrid.getGridInstance.cancelEditData();
		},
		onChangeSearchDay() {
			const regDt = this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
			this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

			this.$_setSearchHistsCustomType(this.searchType.paramsData);
		},
		/** @description : 날짜 형식 변환 메서드 */
		formatDt(rowData) {
			if (rowData?.surDt) {
				return this.$_commonlib.formatDate(rowData.surDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
			}
		},
		/** @description : 설문 기간 날짜 형식 변환 메서드 */
		regFormatDt(date) {
			return this.$_commonlib.formatDate(date, 'YYYYMMDD', 'YYYY.MM.DD');
		},
		/** @description : 데이터 검색 메서드 */
		async selectDataList() {
			/* if (
				!vm.searchType.customTypes.dayStart ||
				!vm.searchType.customTypes.dayEnd ||
				!vm.searchType.customTypes.fromCnt ||
				!vm.searchType.customTypes.toCnt
			) {
				return;
			} */

			if (!vm.searchValidationGroup.validate().isValid) {
				return;
			}

			this.dataGrid.dataSource = new CustomStore({
				key: 'id',
				async load(loadOptions) {
					let params = vm.$_getDxDataGridParam(loadOptions);
					/* if (!params.sort) {
						params.sort = sort;
					} */

					vm.searchType.paramsData['startDt'] = vm.searchType.customTypes.dayStart;
					vm.searchType.paramsData['endDt'] = vm.searchType.customTypes.dayEnd;
					vm.searchType.paramsData['fromCnt'] = vm.searchType.customTypes.fromCnt;
					vm.searchType.paramsData['toCnt'] = vm.searchType.customTypes.toCnt;

					params = { ...params, ...vm.searchType.paramsData };

					const payload = {
						actionname: 'EWM_EXAM_SHEET_LIST',
						data: params,
						loading: false,
						useErrorPopup: true,
					};

					const res = await vm.CALL_EWM_API(payload);

					if (isSuccess(res)) {
						const rtnData = {
							data: res.data.data,
							totalCount: res.data.header.totalCount,
						};
						vm.$refs.sheetGrid.totalCount = rtnData.totalCount;
						return rtnData;
					} else {
						return () => {
							throw 'Data Loading Error';
						};
					}
				},
			});
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			//this.codeMap = await this.$_getCodeMapList('root_edu_exam_type');
		},
		/** @description : 라이프사이클 created 호출되는 메서드 */
		createdData() {
			vm = this;

			/* this.initCodeMap().then(() => {
				this.codes.examSheetType.dataSource = this.$_enums.ewm.examSheetType.values;
				this.$refs.sheetGrid.getGridInstance.columnOption('examSheetType', 'lookup', this.codes.examSheetType);
			}); */
		},
		/** @description : 라이프사이클 mounted 호출되는 메서드 */
		mountedData() {
			this.searchType.paramsData = null;
			this.onChangeSearchDay();
			this.selectDataList();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>

<style scoped>
.sub_new_style01 .page_search_box .inner div {
	display: inline-block;
}
.sub_new_style01 .page_search_box .inner > div {
	vertical-align: middle;
	margin-right: 10px;
}
.page_search_box .inner .box-btn-search {
	margin-right: 15px;
}
</style>
